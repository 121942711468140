import wrapWithProvider from './wrap-with-provider';
// import * as Sentrypkg from '@sentry/browser';
// Sentry.init({
//   dsn: 'https://cf4df36eb0f840809aadab43ec2a6935@o391970.ingest.sentry.io/5903709',
//   integrations: [new Sentry.Replay()],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
const solvePageview = () => {
  if (!process.env.SOLVE_API_URL_SLUG) return;
  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    try {
      window.solve.pageview();
    } catch (e) {}
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null;
  solvePageview();
};

export const wrapRootElement = wrapWithProvider;
