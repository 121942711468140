import find from 'lodash/find';
import { trackPurchase, trackSubscribe } from 'services/facebook.api';
import { getUserProfile } from 'services/userProfile.api';
import { isBrowser } from './common';
import { BasketLine, ProductInfo, Product } from 'services/models';
import {
  trackProfileInSolve,
  trackPurchaseInSolve,
  trackUserIdInSolve,
} from './solve_analytics';

// export const STEP_LOGGING_IN = 1;
// export const STEP_REVIEW_ORDERED_ITEMS = 3;

export const STEP_DOG_NAME = 1;
export const STEP_DOG_DETAILS = 2;
export const STEP_DOG_DIET_DETAILS = 3;
export const STEP_ENTER_USER_DETAILS = 4;
export const STEP_VIEW_FEEDING_PLAN = 5;
export const STEP_CONTINUE_CHECKOUT = 6;
export const STEP_DELIVERY_INFORMATION = 7;
export const STEP_ADD_PAYMENT_DETAILS = 8;
export const STEP_PAYMENT = 9;

const addToDataLayer = (data: any) => {
  if (isBrowser()) {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push(data);
  }
};

export const getProfileAndTrackUser = async () => {
  const profileInfo = await getUserProfile();
  isBrowser() && window.localStorage.setItem('userID', profileInfo.id);
  isBrowser() && trackProfileInSolve(profileInfo);
  trackUserId();
};

export const trackUserId = () => {
  if (isBrowser()) {
    const userID = window.localStorage.getItem('userID') || '';
    addToDataLayer({ userID: userID });
    trackUserIdInSolve(userID);
  }
};

export const trackCheckout = (stepNo: number, addedProducts: ProductInfo[]) => {
  const prodArray = addedProducts.map(item => {
    const { title, id, price, product_type, product_class, quantity } = item;
    return {
      id,
      quantity,
      name: title,
      price: price.incl_tax,
      category: product_class,
      type: product_type,
    };
  });
  //console.log('addToDataLayer',stepNo, addedProducts);

  addToDataLayer({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step: stepNo,
        },
        products: prodArray,
      },
    },
  });
};

export const recordPurchase = async (
  pathname: string,
  orderSummary: any,
  basketLines: BasketLine[],
  firstOrder = false,
  productsList = [] as ProductInfo[],
  user_data = { profile_email: '', phone_number: '' },
) => {
  const {
    number: transaction_id,
    total_incl_tax,
    total_excl_tax,
    shipping_incl_tax,
    order_summary,
    offer_discounts,
  } = orderSummary;

  trackPurchaseInSolve(transaction_id, user_data);

  const productsForPurchaseRecord = [] as any;
  const productsForAnalytics = [] as any;

  if (basketLines.length > 0) {
    basketLines.forEach(async (item, index) => {
      const productData = find(productsList, {
        url: item.product,
      }) as ProductInfo;
      const { title, id, price, product_class, product_type } = productData;

      if (productData) {
        productsForPurchaseRecord.push({
          sku: id,
          name: title,
          category: product_class,
          price: price.incl_tax,
          quantity: order_summary[index].quantity,
        });
        productsForAnalytics.push({
          id,
          name: title,
          category: product_class,
          price: price.incl_tax,
          quantity: order_summary[index].quantity,
          type: product_type,
        });
      }
    });
  }

  addToDataLayer({
    event: 'purchase',
    transactionId: transaction_id,
    transactionTotal: total_incl_tax,
    transactionTax: Number(total_incl_tax) - Number(total_excl_tax),
    transactionShipping: shipping_incl_tax,
    transactionProducts: productsForPurchaseRecord,
  });

  addToDataLayer({
    ecommerce: {
      purchase: {
        actionField: {
          id: transaction_id, //Order ID. Required
          revenue: total_incl_tax, //total transaction value, includes tax and shipping
          tax: Number(total_incl_tax) - Number(total_excl_tax),
          shipping: shipping_incl_tax,
          coupon: offer_discounts?.length ? offer_discounts[0] : '', // name of coupon, optional field, leave it blank if no coupon used
        },
        products: productsForAnalytics,
      },
    },
  });

  trackPurchase(
    pathname,
    total_incl_tax,
    user_data.profile_email,
    user_data.phone_number,
  );

  if (firstOrder) {
    trackSubscribe(
      pathname,
      total_incl_tax,
      user_data.profile_email,
      user_data.phone_number,
    );
  }
};

export const trackAddOrRemoveItem = (
  item: Product,
  operation: string,
  quantity: number = 0,
) => {
  const { title, id, price, product_class } = item;
  const dataToTrack = {
    id,
    name: title,
    category: product_class,
    price: price.incl_tax,
    quantity: quantity || 1,
  };

  if (operation === 'add') {
    addToDataLayer({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'NZD',
        add: {
          products: [dataToTrack],
        },
      },
    });
  } else if (operation === 'remove') {
    addToDataLayer({
      event: 'removeFromCart',
      ecommerce: {
        remove: {
          products: [dataToTrack],
        },
      },
    });
  }
};

export const trackProductImpressions = (allProducts: Product[]) => {
  const dataToTrack = allProducts.map((item, index) => {
    const { title, id, price, product_type } = item;
    return {
      id,
      price: price.incl_tax,
      name: title,
      position: index,
      list: 'Category List',
      category: product_type,
    };
  });
  addToDataLayer({
    event: 'eecView',
    ecommerce: {
      impressions: dataToTrack,
    },
  });
};

export const trackProductClick = (productItem: Product, position: number) => {
  const { title, id, price, product_type } = productItem;

  addToDataLayer({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: product_type },
        products: [
          {
            id,
            position,
            name: title,
            list: 'Category List',
            price: price.incl_tax,
            category: product_type,
          },
        ],
      },
    },
  });
};

export const trackProductDetailsView = (productItem: Product) => {
  const { title, id, price, product_type } = productItem;

  addToDataLayer({
    event: 'eecView',
    ecommerce: {
      detail: {
        products: [
          {
            id,
            name: title,
            price: price.incl_tax,
            category: product_type,
          },
        ],
      },
    },
  });
};
