import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { StatusType } from '../../types';
import StatusMessage, { getAlertClasses } from './StatusMessage';

interface Props {
  children: React.ReactNode;
}

interface NotificationToastContextState {
  showNotificationToast: (
    message: string,
    type?: StatusType,
    delay?: number,
  ) => void;
  hideNotificationToast: () => void;
  toastMessage: string;
  isToastVisible: boolean;
  toastType: StatusType;
  toastDelay: number;
}

const initialNotificationToastState = {
  toastMessage: '',
  isToastVisible: false,
  toastType: StatusType.success,
  toastDelay: 4000,
} as NotificationToastContextState;

const NotificationToastContext = React.createContext(
  {} as NotificationToastContextState,
);

const NotificationToastProvider = (props: Props) => {
  const [isToastVisible, setIsToastVisible] = useState(
    initialNotificationToastState.isToastVisible,
  );
  const [toastMessage, setToastMessage] = useState(
    initialNotificationToastState.toastMessage,
  );
  const [toastType, setToastType] = useState(
    initialNotificationToastState.toastType,
  );
  const [toastDelay, setToastDelay] = useState(
    initialNotificationToastState.toastDelay,
  );
  const showNotificationToast = (
    message: string,
    type?: StatusType,
    delay?: number,
  ) => {
    if (message) {
      setToastMessage(message);
      type && setToastType(type);
      delay && setToastDelay(delay);
      setIsToastVisible(true);
    }
  };
  const hideNotificationToast = () => {
    setToastMessage(initialNotificationToastState.toastMessage);
    setToastType(initialNotificationToastState.toastType);
    setToastDelay(initialNotificationToastState.toastDelay);
    setIsToastVisible(initialNotificationToastState.isToastVisible);
  };

  return (
    <NotificationToastContext.Provider
      value={{
        showNotificationToast,
        hideNotificationToast,
        isToastVisible,
        toastMessage,
        toastType,
        toastDelay,
      }}
    >
      {props.children}
      {isToastVisible ? (
        <div className="toast-parent">
          <Toast
            className={getAlertClasses(toastType).join(' ')}
            onClose={() => hideNotificationToast()}
            show={true}
            autohide
            delay={toastDelay}
            animation={true}
            style={{zIndex:99999}}
          >
            <Toast.Header></Toast.Header>
            <Toast.Body>
              <StatusMessage
                message={toastMessage}
                type={toastType}
                isToast={true}
              />
            </Toast.Body>
          </Toast>
        </div>
      ) : (
        <></>
      )}
    </NotificationToastContext.Provider>
  );
};

export const useNotificationToastContext = (): NotificationToastContextState => {
  return React.useContext(NotificationToastContext);
};

export default NotificationToastProvider;
