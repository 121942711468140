import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppView } from '../types';

interface AppProps {
  appView: AppView;
}

const initialState: AppProps = {
  appView: AppView.HOME,
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setAppView: (state, action: PayloadAction<AppView>) => {
      state.appView = action.payload;
    },
  },
});

export const { setAppView } = appSlice.actions;
export default appSlice.reducer;
