import { isBrowser } from '../common';

let solveLifecycleHandlerId: undefined | number;
let identifierInformation: Record<string, string> = {},
  profileInformation: undefined | Record<string, any>,
  cartId: undefined | string,
  orderId: undefined | string,
  productId: undefined | string;

export const trackLogoutInSolve = () => {
  ensureLifecycleHandler();

  // Tell solve to delete this session. This prevents subsequent events from
  //  being linked to this profile.
  isBrowser() && window.solve?.destroy();
  identifierInformation = {};
  profileInformation = undefined;
  cartId = undefined;
  orderId = undefined;
  productId = undefined;
};

export const trackProfileInSolve = profileInfo => {
  // Ensure we have a handler attached to the solve SDK.
  ensureLifecycleHandler();

  if (profileInfo.id) {
    identifierInformation['fmfb_id'] = profileInfo.id;
  }
  if (profileInfo.email) {
    identifierInformation['email'] = profileInfo.email;
  }
  if (profileInfo.phone_number) {
    identifierInformation['phone_number'] = profileInfo.phone_number;
  }
  profileInformation = {
    first_name: profileInfo.first_name,
    last_name: profileInfo.last_name,
    gender: profileInfo.gender,
    dob: profileInfo.dob,
    allow_marketing_mail: profileInfo.allow_marketing_mail,
    is_social_user: profileInfo.is_social_user,
  };
};
export const trackUserIdInSolve = (userId: string) => {
  // Ensure we have a handler attached to the solve SDK.
  ensureLifecycleHandler();

  if (userId) {
    identifierInformation['fmfb_id'] = userId;
  }
};
export const trackProductViewInSolve = (trackedProductId: string) => {
  ensureLifecycleHandler();
  productId = trackedProductId;
};

export const trackPurchaseInSolve = (
  transactionId: string,
  userData: { profile_email: string; phone_number: string },
) => {
  ensureLifecycleHandler();

  if (transactionId) {
    orderId = transactionId;
  }
  if (userData.profile_email) {
    identifierInformation['email'] = userData.profile_email;
  }
  if (userData.phone_number) {
    identifierInformation['phone_number'] = userData.phone_number;
  }
};

export const trackCartInSolve = (basketId: number) => {
  ensureLifecycleHandler();

  if (basketId) {
    cartId = `${basketId}`;
  }
};
export const clearTrackedCartInSolve = () => {
  ensureLifecycleHandler();
  cartId = undefined;
};

export const ensureLifecycleHandler = () => {
  if (solveLifecycleHandlerId === undefined && isBrowser() && window.solve) {
    solveLifecycleHandlerId = window.solve.addLifecycleHookHandler(
      'beforesend',
      (event: Record<string, any>) => {
        event.store = 'default';

        if (profileInformation) {
          event.payload = {
            ...event.payload,
            // Merge the profile information if it exists
            profile: {
              ...(event.payload?.profile || {}),
              ...profileInformation,
              identifiers: Object.entries(identifierInformation).map(
                ([ident_type, ident_value]) => ({
                  type: ident_type,
                  key: ident_value,
                }),
              ),
            },
          };
        }
        if (cartId) {
          event.cart_id = cartId;
        }
        if (orderId) {
          event.order_id = orderId;
          if (event.type === 'pageview') {
            // Order ID is only set on the order summary page after completing
            //  the order.
            orderId = undefined;
            // Also clear the cart once the order is complete.
            cartId = undefined;
          }
        }
        if (event.type === 'pageview' && productId) {
          event.product_id = productId;
          // Only want a product ID associated with the product pageview so
          //  clear the Product ID after sending this pageview
          productId = undefined;
        }
        return event;
      },
    );
  }
};
