import basketReducer from './basketSlice';
import productsReducer from './productsSlice';
import appReducer from './appSlice';
import { combineReducers } from '@reduxjs/toolkit';

export default combineReducers({
  basket: basketReducer,
  products: productsReducer,
  appState: appReducer,
});
