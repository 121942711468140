export enum StatusType {
  warning = 'warning',
  error = 'error',
  success = 'success',
  none = 'none',
  placed = 'Placed',
  info = 'info',
}

export enum AlertClassTypes {
  MAIN = 'main',
  ICON = 'icon',
}

export enum AppView {
  ACCOUNT = 'account',
  HOME = 'home',
}

export enum PurchaseType {
  ADD_ON = 'ADDON',
  ONE_OFF = 'ONEOFF',
}
