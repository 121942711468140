import axiosCustom from './customAxios';
import { v4 as uuidv4 } from 'uuid';
import { getUA } from 'react-device-detect';
import { isBrowser } from '../utils';
import { isFunction } from 'lodash';
import ReactPixel from 'react-facebook-pixel';

const urlEndpoint = 'tracking/';

const trackFBEvent = async (pathName: string, data: any) => {
  const trackingData = {
    ...data,
    event_source_url:
      process.env.NODE_ENV === 'development'
        ? `https://fmfb-v2-dev.netlify.app${pathName}`
        : `${process.env.SITE_URL}${pathName}`,
    client_user_agent: getUA,
  };
  return await axiosCustom.post(urlEndpoint, trackingData);
};

export const trackLead = (pathName: string, email: string) => {
  let eventId = uuidv4();
  trackFBEvent(pathName, {
    event_name: 'Lead',
    user_data: { em: email, ph: '' },
    event_id: eventId,
  });

  if (isBrowser() && process.env.FB_PIXEL_ID) {
    // Facebook pixel tracking
    // if (window.fbq && isFunction(window.fbq)) {
    //   window.fbq('track', 'Lead', {}, { eventID: eventId });
    // }
  }
};

export const trackPurchase = (
  pathName: string,
  purchaseValue: number,
  email: string,
  phone: string,
) => {
  let eventId = uuidv4();

  trackFBEvent(pathName, {
    event_name: 'Purchase',
    event_id: eventId,
    user_data: { em: email, ph: phone },
    custom_data: {
      currency: 'NZD',
      value: purchaseValue,
    },
  });

  if (isBrowser() && process.env.FB_PIXEL_ID) {
    // Facebook pixel tracking
    // if (window.fbq && isFunction(window.fbq)) {
    //   window.fbq(
    //     'track',
    //     'Purchase',
    //     {
    //       currency: 'NZD',
    //       value: purchaseValue,
    //     },
    //     { eventID: eventId },
    //   );
    // }
  }
};

export const trackInitiateCheckout = (
  pathName: string,
  purchaseValue: number,
  email: string,
) => {
  let eventId = uuidv4();

  trackFBEvent(pathName, {
    event_id: eventId,
    event_name: 'InitiateCheckout',
    user_data: { em: email, ph: '' },
    custom_data: {
      currency: 'NZD',
      value: purchaseValue,
    },
  });

  if (isBrowser() && process.env.FB_PIXEL_ID) {
    // Facebook pixel tracking
    // if (window.fbq && isFunction(window.fbq)) {
    //   window.fbq(
    //     'track',
    //     'InitiateCheckout',
    //     {
    //       currency: 'NZD',
    //       value: purchaseValue,
    //     },
    //     { eventID: eventId },
    //   );
    // }
  }
};

export const trackSubscribe = (
  pathName: string,
  purchaseValue: number,
  email: string,
  phone: string,
) => {
  let eventId = uuidv4();

  trackFBEvent(pathName, {
    event_name: 'Subscribe',
    event_id: eventId,
    user_data: { em: email, ph: phone },
    custom_data: {
      currency: 'NZD',
      value: purchaseValue,
    },
  });

  if (isBrowser() && process.env.FB_PIXEL_ID) {
    // Facebook pixel tracking
    // if (window.fbq && isFunction(window.fbq)) {
    //   window.fbq(
    //     'track',
    //     'Subscribe',
    //     {
    //       value: purchaseValue,
    //       currency: 'NZD',
    //       predicted_ltv: '0.00',
    //     },
    //     { eventID: eventId },
    //   );
    // }
  }
};

export const fbPixelTracking = (
    custom:boolean,
    eventType: string,
    data: any,
) => {
  if (isBrowser() && process.env.FB_PIXEL_ID) {
    // Facebook pixel tracking
    if (typeof window !== 'undefined') {
      import('react-facebook-pixel')
      .then((ReactPixelModule) => {
          const ReactPixel = ReactPixelModule.default;
          ReactPixel.init(`${process.env.FB_PIXEL_ID}`);
          if (custom) { 
            ReactPixel.trackCustom(eventType, data);
          }
          else {
            ReactPixel.track(eventType, data);
          }
        })
        .catch((error) => {
          console.error("Facebook Pixel failed to load", error);
        });
    }
  }
};
