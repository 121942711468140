import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { navigate } from 'gatsby';
import isString from 'lodash/isString';
import { StatusType, AlertClassTypes } from '../../types';

interface StatusMessageProps {
  message: string | string[];
  type: StatusType;
  link?: string;
  linkText?: string;
  isToast?: boolean;
}

export const getAlertClasses = (
  statusType: StatusType,
  classType?: AlertClassTypes,
) => {
  const alertClasses = ['alert'];
  const alertIconClasses = ['alert-icon'];
  switch (statusType) {
    case StatusType.error:
      alertClasses.push('alert-danger');
      alertIconClasses.push('alert-danger-icon');
      break;
    case StatusType.warning:
      alertClasses.push('alert-warning');
      alertIconClasses.push('alert-warn-icon');
      break;
    case StatusType.info:
      alertClasses.push('alert-info');
      alertIconClasses.push('alert-info-icon');
      break;
    default:
      alertClasses.push('alert-success');
      alertIconClasses.push('alert-success-icon');
      break;
  }

  switch (classType) {
    case AlertClassTypes.ICON:
      return alertIconClasses;
    default:
      return alertClasses;
  }
};

const StatusMessage: React.FC<StatusMessageProps> = props => {
  const alertClasses = getAlertClasses(props.type);
  const alertIconClasses = getAlertClasses(props.type, AlertClassTypes.ICON);
  let message = '';
  let messages: string[] = [];
  if (props.message.length) {
    if (isString(props.message)) {
      message = props.message;
    } else {
      if (props.message.length > 1) {
        messages = props.message;
      } else {
        message = props.message[0];
      }
    }
  }
  const alertContent = () => {
    return (
      <div className="alert-message">
        <i className={alertIconClasses.join(' ')}></i>
        {message ? (
          <span className="alert-message-text">{props.message}</span>
        ) : messages.length ? (
          <ul className="alert-message-texts">
            {messages.map((m, i) => (
              <li key={i}>{m}</li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {props.message &&
        (!props.isToast ? (
          <Row className="mgn-top-20">
            <Col>
              <div className={alertClasses.join(' ')} role="alert">
                {alertContent()}
                {props.link && (
                  <Button
                    className="alert-btn-lt"
                    onClick={() => props.link && navigate(props.link)}
                  >
                    {props.linkText ? props.linkText : 'Click here'}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          alertContent()
        ))}
    </>
  );
};

export default StatusMessage;
