import axiosCustom from './customAxios';
import { PackageSuggestionRequest, PackageSuggestion } from './models';
const path = 'product-suggestion/';

export const getRecomendedProducts = async (): CustomResponse<Product[]> => {
  const { data: products, error } = await axiosCustom.get(path);
  if (error) return { error, data: [] };
  return {
    data: products?.map((item: Product) => ({
      ...item,
      price_incl_tax: parseFloat(item.price.incl_tax),
    })),
    error: null,
  };
};