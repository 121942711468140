export enum ProductAttributeName {
  AVAILABLE_AS = 'Available As',
}

export enum ProductAttributeValues {
  ADD_ON = 'Add on',
  SUBSCRIPTION = 'Subscription',
  ONE_OFF = 'One Off',
}
export interface ProductAttribute {
  code: string;
  name: ProductAttributeName;
  value: string;
}

export interface ProductCategory {
  name: string;
  id: number;
  slug: string;
}

export interface Ancestors {
  ancestors: ProductCategory[];
}
export interface Product {
  url: string;
  id: number;
  title: string;
  upc: string;
  product_type: string;
  product_class: ProductClassType;
  image: string;
  is_public: boolean;
  is_suggestible: boolean;
  is_subscribable: boolean;
  price: Price;
  product_attributes: ProductAttribute[];
  items_in_cart_limit: number;
  price_incl_tax?: number;
  subscription_price: number;
  images?: ProductImgObject[];
  description: string;
  nutritional_description?: string;
  nutritional_information?: {
    nutritional_description: string;
    calories_per_kg: number;
    crude_protein: string;
    crude_fat: string;
    crude_fibre: string;
    moisture: string;
    calcium: string;
    phosphorus: string;
    grams_per_cup: number;
  };
  additional_information?: string;
  shipping_charge?: number;
  hasInfoFetched?: boolean;
  weightage: number;
  slug: string;
  categories: (ProductCategory & Ancestors)[];
}

export enum ProductClassType {
  'ADD_ONS' = 'ADD-ONS',
  'PROTEIN' = 'Protein Foods',
  'ONEOFF' = 'ONE OFF',
}

export interface GetProductsResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Array<Product>;
}

export interface Price {
  currency: string;
  excl_tax: string;
  incl_tax: string;
  tax: string;
}

export interface ProductImgObject {
  caption: string;
  date_created: Date;
  display_order: number;
  id: number;
  original: string;
  product: number;
}

export interface ProductInfo {
  id: number;
  price_incl_tax: number;
  subscription_price: number;
  url: string;
  image: string;
  images: ProductImgObject[];
  description: string;
  title: string;
  product_type: string;
  product_class: ProductClassType;
  is_public: boolean;
  is_suggestible: boolean;
  is_subscribable: boolean;
  nutritional_description: string;
  nutritional_information: {
    calories_per_kg: number;
    crude_protein: string;
    crude_fat: string;
    crude_fibre: string;
    moisture: string;
    calcium: string;
    phosphorus: string;
    grams_per_cup: number;
  };
  price: Price;
  product_attributes: ProductAttribute[];
  additional_information: string;
  shipping_charge: number;
  items_in_cart_limit: number;
  weightage: number;
  slug: string;
  categories: (ProductCategory & Ancestors)[];
}
