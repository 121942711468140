import { DogsAgeType } from '../../services/models/dogsAge';
import { isBrowser } from '../common';

export type PetSignupDogNameAndAge = {
  dog_name: string;
  age: string;
  age_data: {
    type: DogsAgeType;
    value: any;
  };
};
export type PetSignupDogWeightAndActivity = PetSignupDogNameAndAge & {
  weight: number;
  lifestyle: string;
  breed: string;
};
export type PetSignupDogFeedPercentage = PetSignupDogWeightAndActivity & {
  feed_percentage: number;
};

export const signupStages = {
  dogNameAndAge: (petInfo: PetSignupDogNameAndAge) => {
    isBrowser() &&
      window.solve?.customEvent('pet_signup_name_and_age', petInfo);
  },
  dogWeightAndActivity: (petInfo: PetSignupDogWeightAndActivity) => {
    isBrowser() &&
      window.solve?.customEvent('pet_signup_weight_and_activity', petInfo);
  },
  dogFeedPercentage: (petInfo: PetSignupDogFeedPercentage) => {
    isBrowser() &&
      window.solve?.customEvent('pet_signup_feed_percentage', petInfo);
  },
};

export type PetSignupComplete = PetSignupDogFeedPercentage;

export const trackPetSignupCompleteInSolve = petInfo => {
  isBrowser() && window.solve?.customEvent('pet_signup_complete', petInfo);
};
