import { AsyncThunk, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Product } from 'models';
import {
  getProductById,
  getProducts,
  getProductsByIds,
} from 'services/products.api';
import { RootState } from './store';
import uniqBy from 'lodash/uniqBy';
import { getRecomendedProducts } from 'services/recommended.api';

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

interface ProductsState {
  entities: Product[];
  recommendedEntities: Product[];
  isLoading: boolean;
  isAllLoading: boolean;
  isLoadingRecommended: boolean;
  error: string | null;
  currentRequestId?: string;
}

const initialState: ProductsState = {
  entities: [],
  recommendedEntities: [],
  isLoading: false,
  isAllLoading: false,
  isLoadingRecommended: false,
  error: null,
  currentRequestId: undefined,
};

//TODO we need to rethink about product and productinfo api calls, its better if we get all the data in product call itself,

//thunks
export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (_, { rejectWithValue, getState, requestId }) => {
    const { products } = getState() as RootState;
    const { currentRequestId, isLoading } = products;
    if (!isLoading || requestId !== currentRequestId) {
      return rejectWithValue('request is on flight');
    }
    const { data, error } = await getProducts();
    if (error) return rejectWithValue(error);
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { products } = getState() as RootState;
      if (products.entities.length > 1) {
        // Already fetched don't need to re-fetch
        return false;
      }
    },
  },
);

export const fetchRecommendedProducts = createAsyncThunk(
  'products/fetchRecommendedProducts',
  async () => {
    const response = await getRecomendedProducts();
    const data = await response.data;
    return data;
  }
);

export const fetchProductDetailsById = createAsyncThunk(
  'products/fetchProductById',
  async (productId: number, { rejectWithValue }) => {
    const id = productId ? productId : process.env.PRODUCT_ID_CHICKEN_BOX;
    const { data, error } = await getProductById(Number(id));
    if (error) return rejectWithValue(error);
    return data;
  },
  {
    condition: (productId, { getState }) => {
      const { products } = getState() as RootState;

      const product = products.entities.find(
        product => product.id === Number(productId),
      );
      if (product?.hasInfoFetched) {
        // Already fetched don't need to re-fetch
        return false;
      }
    },
  },
);

// TODO its been used in acccount once we move account also in redux. we can reuse this
// const fetchProductPriceById = createAsyncThunk(
//   'products/fetchProductById',
//   async (productId: number, { rejectWithValue }) => {
//     const id = productId ? productId : process.env.PRODUCT_ID_CHICKEN_BOX;
//     const { data, error } = await getProductPrice(Number(id));
//     if (error) return rejectWithValue(error);
//     return data;
//   },
// );

//TODO instead of making multiple xhr calls for each productId, we should modify the backend to accept array of ids
export const fetchProductDetailsByIds = createAsyncThunk(
  'products/fetchProductDetailsByIds',
  async (productIds: number[], { rejectWithValue }) => {
    const { data, error } = await getProductsByIds(productIds);
    if (error) return rejectWithValue(error);
    return data;
  },
);

export const fetchAllProductDetails = createAsyncThunk(
  'products/fetchAllProductDetails',
  async (_, { rejectWithValue, getState }) => {
    const { products } = getState() as RootState;
    const allProductIds = products.entities.map(({ id }) => Number(id));
    const { data, error } = await getProductsByIds(allProductIds);
    if (error) return rejectWithValue(error);
    return data;
  },
);

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.fulfilled, (state, action) => {
        const products = [...state.entities, ...action.payload];
        const remaining = uniqBy(products, 'id');
        state.entities = remaining;
        state.isAllLoading=false;
      })
      .addCase(fetchProducts.pending, (state) => {
        state.isAllLoading = true;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isAllLoading = false;
      })
      .addCase(fetchRecommendedProducts.pending, (state) => {
        state.isLoadingRecommended = true;
      })
      .addCase(fetchRecommendedProducts.fulfilled, (state, action) => {
        state.isLoadingRecommended = false;
        state.recommendedEntities = action.payload;
      })
      .addCase(fetchRecommendedProducts.rejected, (state, action) => {
        state.isLoadingRecommended = false;
        // state.error = action.error.message;
      })
      .addCase(fetchProductDetailsById.fulfilled, (state, action) => {
        const productInfo = action.payload;
        productInfo.hasInfoFetched = true;
        //check if product is already in state.
        const hasProductInState = !!state.entities.find(
          product => product.id === productInfo.id,
        );

        if (hasProductInState) {
          state.entities = state.entities.map(product => {
            if (product.id === productInfo.id) {
              product = { ...productInfo, ...product };
            }
            return product;
          });
        } else {
          state.entities.push(productInfo);
        }
      })
      .addCase(fetchProductDetailsByIds.fulfilled, (state, action) => {
        const productsInfo = action.payload;

        productsInfo.forEach(productInfo => {
          //check if product is already in state.
          const hasProductInState = !!state.entities.find(
            product => product.id === productInfo.id,
          );

          if (hasProductInState) {
            state.entities = state.entities.map(product => {
              if (product.id === productInfo.id) {
                product = { ...productInfo, ...product };
              }
              return product;
            });
          } else {
            state.entities.push(productInfo);
          }
        });
      })
      .addMatcher(
        (action): action is RejectedAction =>
          action.type.startsWith('products') &&
          action.type.endsWith('/rejected'),
        (state, action) => {
          state.isLoading = false;
          state.currentRequestId = undefined;
        },
      )
      .addMatcher(
        (action): action is PendingAction =>
          action.type.startsWith('products') &&
          action.type.endsWith('/pending'),
        (state, action) => {
          state.isLoading = true;
          state.currentRequestId = action.meta.requestId;
        },
      )
      .addMatcher(
        (action): action is FulfilledAction =>
          action.type.startsWith('products') &&
          action.type.endsWith('/fulfilled'),
        state => {
          state.isLoading = false;
          state.currentRequestId = undefined;
        },
      );
  },
});

export default productsSlice.reducer;
