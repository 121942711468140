import axios, { AxiosResponse } from 'axios';
import flatten from 'lodash/flatten';
import { checkWordExistence } from '../../src/utils';

const axiosCustom = axios.create({
  withCredentials: true,
});
axiosCustom.defaults.headers.common['Accept'] = 'application/json';
axiosCustom.defaults.headers.common['Content-Type'] = 'application/json';
//TODO make base api configurable based on ENV.
axiosCustom.defaults.baseURL = `${process.env.GATSBY_API_URL}/api/`;
// axiosCustom.defaults.baseURL = `http://localhost:9000/api/`;

axiosCustom.interceptors.request.use(function(req) {
  let token = localStorage.getItem('token');
  if (!token) return req;
  req.headers = Object.assign(
    {
      Authorization: `Token ${token}`,
    },
    req.headers,
  );
  return req;
});

axiosCustom.interceptors.response.use(
  function(response) {
    return { ...response, error: undefined };
  },
  function(error) {
    let errorMessages = [];
    if (error.response) {
      const { data } = error.response;

      if (typeof data !== 'string') {
        errorMessages = flatten(Object.values(data));
      } else {
        errorMessages.push(data);
      }
    }
    if (!checkWordExistence(errorMessages||[], "stripe")) {
      Sentry.withScope(scope => {
        scope.setFingerprint([
          '{{ default }}',
          error.config.url?.replace(/\d+/g, ':id'),
        ]);
        Sentry.captureException(error, {
          extra: { body: JSON.stringify(errorMessages) },
        });
      });
    }
    return Promise.resolve({ data: undefined, error: errorMessages });
  },
);

export type CustomResponse<T> = Promise<{
  data: T;
  error: string[] | undefined | any;
}>;

export default axiosCustom;
