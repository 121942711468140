import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import NotificationToastProvider from 'components/common/NotificationToast';
import store from './src/state/store';
import { fetchCurrentBasket, fetchLines, fetchProducts, fetchRecommendedProducts } from './src/state';

import { useAppDispatch } from './src/state/hooks';

// we need to keep this RootApp as we cant use useDispatch inside the wrapWith Provider
const RootApp = props => {
  const dispatch = useAppDispatch();

  // fetching basket and products in app start
  useEffect(() => {
    (async () => {
      //previously all requests where awaited . removed to test
      await dispatch(fetchCurrentBasket());
      await dispatch(fetchLines());
      await dispatch(fetchProducts());
      await dispatch(fetchRecommendedProducts());
    })();
  }, []);

  return <>{props.element}</>;
};

export default function wrapWithProvider({ element }) {
  return (
    <Provider store={store}>
      <NotificationToastProvider>
        <RootApp element={element}></RootApp>
      </NotificationToastProvider>
    </Provider>
  );
}
