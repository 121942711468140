import axiosCustom from './customAxios';
import { Profile, ChangePasswordRequest } from './models';

const END_POINTS = {
  profile: '/my-profile/',
  password: '/rest-auth/password/change/',
};

export const getUserProfile = async () => {
  const { data, error } = await axiosCustom.get(END_POINTS.profile);
  if (error) {
    throw error;
  }
  return data;
};

export const updateUserProfile = async (body: Profile) => {
  const { data, error } = await axiosCustom.put(END_POINTS.profile, body);
  if (error) {
    throw error;
  }
  return data;
};

export const updatePassword = async (body: ChangePasswordRequest) => {
  const { data, error } = await axiosCustom.post(END_POINTS.password, body);

  if (error) {
    throw error;
  }
  return data;
};
