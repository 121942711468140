import axiosCustom from './customAxios';
import { CheckHomePageOfferResponse, VoucherResponse } from './models';

const path = 'uservoucher/';
const addVoucherPath = 'basket/add-voucher/';

export const getVouchers = async (): Promise<VoucherResponse[]> => {
  const { data, error } = await axiosCustom.get(path);
  return data;
};

export const addVoucherToBasket = async (vouchercode: string): Promise<any> => {
  const { data, error } = await axiosCustom.post(addVoucherPath, {
    vouchercode,
  });
  if (error) {
    return {
      status: 'failure',
      response: error[0],
    };
    throw error;
  }
  return {
    status: 'success',
    response: data,
  };
};

export const sendInviteLink = async (emails: string): Promise<any> => {
  const allEmails = emails.split(',').map(item => item.trim());
  const { data, error } = await axiosCustom.post('invite/', {
    email: allEmails,
  });
  if (error) {
    throw error;
  }
  return data;
};

export const addInviteCodeToUserSession = async (
  invitecode: string,
): Promise<any> => {
  const { data, error } = await axiosCustom.post('store-invitecode/', {
    invitecode,
  });
  if (error) {
    throw error;
  }
  return data;
};

export const checkHomePageOffer = async (): Promise<CheckHomePageOfferResponse> => {
  const { data, error } = await axiosCustom.get('check-hpoffer/');
  return error ? { error } : { data };
};
