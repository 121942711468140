import axiosCustom, { CustomResponse } from './customAxios';
import { Product, Price, ProductInfo } from './models';

const path = 'products/';

export const getProducts = async (): CustomResponse<Product[]> => {
  const { data: products, error } = await axiosCustom.get(path);
  if (error) return { error, data: [] };
  return {
    data: products?.map((item: Product) => ({
      ...item,
      price_incl_tax: parseFloat(item.price.incl_tax),
    })),
    error: null,
  };
};

export const getProductById = async (
  productId?: number,
): CustomResponse<Product> => {
  const id = productId || process.env.PRODUCT_ID_CHICKEN_BOX;
  return await axiosCustom.get(`${path}${id}/`);
};

export const getProductPrice = async (productId?: number): Promise<Price> => {
  const id = productId || process.env.PRODUCT_ID_CHICKEN_BOX;
  const { data, error } = await axiosCustom.get(`${path}${id}/price/`);
  if (error) {
    return error;
  }
  return data;
};

export const getProductsDetailedInfo = async (): CustomResponse<ProductInfo[]> => {
  const { data, error } = await getProducts();
  if (error) return { error, data: [] };
  const results = [] as ProductInfo[];
  await Promise.all(
    data?.map(async item => {
      const { data: productInfo, error: prodErr } = await getProductById(
        item.id,
      );
      if (prodErr) {
        results.push({
          ...item,
          is_public: item.is_public,
        } as ProductInfo);
      }
      results.push({
        ...productInfo,
        ...item,
        is_public: item.is_public,
        price_incl_tax: parseFloat(productInfo.price?.incl_tax),
      } as ProductInfo);
    }),
  );
  return { data: results, error: null };
};

export const getProductsByIds = async (
  productIds: number[],
): CustomResponse<Product[]> => {
  const results: Product[] = [];
  try {
    await Promise.all(
      productIds.map(async id => {
        const productInfo = await getProductById(Number(id));
        results.push(productInfo.data || {});
      }),
    );
    return Promise.resolve({ data: results, error: undefined });
  } catch (error) {
    return Promise.reject({ data: undefined, error });
  }
};
