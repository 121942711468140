import axiosCustom, { CustomResponse } from './customAxios';
import { Basket, BasketLine } from './models';
import { PurchaseType } from '../types';

const path = 'basket/';
const newBasketPath = 'basket/new/';
const addBasketPath = 'basket/add-product/';
const addMultipleBasketPath = 'basket/add-multiple-products/';
const saveBasketPath = 'basket/save/';
const populateBasketPath = 'basket/repopulate/';
const addVoucherPath = 'basket/add-voucher/';
const paymentDetailPath = 'sessionId/';

export interface saveBasketApiInput {
  email: string;
  first_name?: string;
  last_name?: string;
}

export interface AddLinePayload {
  url: string;
  quantity?: number;
  petId?: number;
  frequency?: number;
  purchaseType?: PurchaseType;
  skipFetchLines?: boolean;
}

export interface AddLineBodyPayload {
  url: string;
  quantity?: number;
  pet_id?: number;
  frequency?: number;
  purchase_type?: string;
}

export interface UpdateLinePayload {
  basketId?: number;
  lineId: number;
  quantity: number;
  frequency: number;
}

export interface ToggleOneOffSubscriptionPayload {
  basketId?: number;
  isSubscribe: boolean;
  frequency?: number;
}

export const ONE_OFF_SUBSCRIPTION_FREQUENCY = 35;

//Basket Api
export const getCurrentBasket = async (): CustomResponse<Basket> =>
  await axiosCustom.get(path);

export const getPaymentDetails = async (data: any): CustomResponse<any> => {
  // await axiosCustom.get(paymentDetailPath);
  return await axiosCustom.post(paymentDetailPath, data);
};

export const getNewBasket = async (): CustomResponse<Basket> =>
  await axiosCustom.get(newBasketPath);

export const saveBasket = async (
  basketData: saveBasketApiInput,
): CustomResponse<Basket> => {
  const { email, first_name, last_name } = basketData;
  const postData = { email } as saveBasketApiInput;

  if (first_name) postData.first_name = first_name;
  if (last_name) postData.last_name = last_name;

  return await axiosCustom.post(saveBasketPath, postData);
};

export const deleteBasket = async (basketId: number): CustomResponse<Basket> =>
  await axiosCustom.delete(`/baskets/${basketId}/`);

export const populateBasket = async (
  basketToken: string,
): CustomResponse<Basket> =>
  await axiosCustom.post(populateBasketPath, {
    token: basketToken,
  });

export const addVoucherToBasket = async (
  vouchercode: string,
): CustomResponse<any> =>
  await axiosCustom.post(addVoucherPath, { vouchercode });

// Lines Api
export const addToBasket = async (
  details: AddLinePayload,
): CustomResponse<Basket> => {
  var formData = new FormData();
  var body = {
    url: details.url,
  } as AddLineBodyPayload;
  details.quantity && (body['quantity'] = details.quantity);
  details.petId && (body['pet_id'] = details.petId);
  details.frequency && (body['frequency'] = details.frequency);
  details.purchaseType && (body['purchase_type'] = details.purchaseType);

  Object.keys(body).forEach(key => {
    const value = body[key];
    formData.set(key, value);
  });

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  return await axiosCustom.post(addBasketPath, formData, config);
};
export const addMultipleToBasket = async (
  details: AddLinePayload[],
): CustomResponse<Basket> => {
  // var formData = new FormData();
  // var body = {
  //   url: details.url,
  // } as AddLineBodyPayload;
  // details.quantity && (body['quantity'] = details.quantity);
  // details.petId && (body['pet_id'] = details.petId);
  // details.frequency && (body['frequency'] = details.frequency);
  // details.purchaseType && (body['purchase_type'] = details.purchaseType);

  // Object.keys(body).forEach(key => {
  //   const value = body[key];
  //   formData.set(key, value);
  // });

  const config = {
    // headers: { 'content-type': 'multipart/form-data' },
  };

  return await axiosCustom.post(addMultipleBasketPath, details, config);
};

export const getAllBasketLines = async (
  basketId: string | number,
): CustomResponse<BasketLine[]> =>
  await axiosCustom.get(`/baskets/${basketId}/lines/`);

export const updateBasketLine = async ({
  basketId,
  lineId,
  quantity,
  frequency,
}: UpdateLinePayload): CustomResponse<BasketLine> =>
  await axiosCustom.put(`baskets/${basketId}/lines/${lineId}/`, {
    basketId,
    lineId,
    frequency,
    quantity,
  });

export const deleteBasketLine = async (
  lineId: number,
  basketId: number = 0,
): CustomResponse<''> =>
  await axiosCustom.delete(`/baskets/${basketId}/lines/${lineId}/`);

export const toggleOneOffSubscriptionApi = async (
  payload: ToggleOneOffSubscriptionPayload,
) => {
  if (payload.isSubscribe) {
    payload.frequency = payload.frequency
      ? payload.frequency
      : ONE_OFF_SUBSCRIPTION_FREQUENCY;
  }
  return await axiosCustom.post(
    `/baskets/${payload.basketId}/set-subscription/`,
    {
      subscribe: payload.isSubscribe,
      frequency: payload.frequency,
    },
  );
};
